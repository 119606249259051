import {AdvertImportType, EstateType, OfferType} from '.cache/__types__';
import {Step, anonymousSteps} from './types';
import {autocomplete} from '@liveComponents/Autocomplete/autocompleteHelper';
import {LocaleType} from '@localTypes/general';

export const getHasDispositions = (estateType: EstateType) => [EstateType.Byt, EstateType.Dum].includes(estateType);
export const getHasPosition = (estateType: EstateType) =>
    [EstateType.Dum, EstateType.RekreacniObjekt, EstateType.NebytovyProstor].includes(estateType);

export const getHasLowEnergy = (estateType: EstateType) => [EstateType.Dum, EstateType.RekreacniObjekt].includes(estateType);

export const getHasSurface = (estateType: EstateType) =>
    [
        EstateType.Byt,
        EstateType.Dum,
        EstateType.Garaz,
        EstateType.Kancelar,
        EstateType.NebytovyProstor,
        EstateType.RekreacniObjekt,
    ].includes(estateType);

export const getHasSurfaceLand = (estateType: EstateType) =>
    [EstateType.Dum, EstateType.Pozemek, EstateType.RekreacniObjekt].includes(estateType);

export const getHasLandType = (estateType: EstateType) => [EstateType.Pozemek].includes(estateType);

export const getIsFlat = (estateType: EstateType) => [EstateType.Byt].includes(estateType);

export const getIsFlatOrHouse = (estateType: EstateType) => [EstateType.Byt, EstateType.Dum].includes(estateType);

export const getIsFlatOrOffice = (estateType: EstateType) => [EstateType.Byt, EstateType.Kancelar].includes(estateType);

export const getIsBuilding = (estateType: EstateType) =>
    [EstateType.Dum, EstateType.Byt, EstateType.Kancelar, EstateType.NebytovyProstor, EstateType.RekreacniObjekt].includes(estateType);

export const getIsRent = (offerType: OfferType) => [OfferType.Pronajem].includes(offerType);

export const getHasEtage = (estateType: EstateType) => [EstateType.Byt, EstateType.Kancelar].includes(estateType);

export const getHasTotalFloors = (estateType: EstateType) =>
    [EstateType.Byt, EstateType.Kancelar, EstateType.Dum, EstateType.RekreacniObjekt].includes(estateType);

export const getIsAddressPoint = (estateType?: EstateType | null) =>
    ![EstateType.Pozemek, EstateType.Garaz].includes(estateType ?? EstateType.Undefined);

export const addPath = (path: Step[], step: Step) => {
    if (path.includes(step)) {
        return path;
    } else if (anonymousSteps.includes(step)) {
        return path;
    }

    return [...path, step];
};

export const getHasPenb = (estateType: EstateType) =>
    [
        EstateType.Byt,
        EstateType.Dum,
        EstateType.Garaz,
        EstateType.Kancelar,
        EstateType.NebytovyProstor,
        EstateType.RekreacniObjekt,
    ].includes(estateType);

export const getHasConstruction = (estateType: EstateType) =>
    [
        EstateType.Byt,
        EstateType.Dum,
        EstateType.RekreacniObjekt,
        EstateType.Garaz,
        EstateType.Kancelar,
        EstateType.NebytovyProstor,
    ].includes(estateType);

export const getHasCondition = (estateType: EstateType) =>
    [
        EstateType.Byt,
        EstateType.Dum,
        EstateType.RekreacniObjekt,
        EstateType.Garaz,
        EstateType.Kancelar,
        EstateType.NebytovyProstor,
    ].includes(estateType);

export const validateDescription = (importType?: AdvertImportType | null) =>
    importType
        ? [
              AdvertImportType.ManualBzrMajitelProdej,
              AdvertImportType.ManualBzrMajitelPronajem,
              AdvertImportType.ManualBzrRkProdej,
              AdvertImportType.ManualBzrRkPronajem,
              AdvertImportType.ManualBzrSpravceProdej,
              AdvertImportType.ManualBzrSpravcePronajem,
              AdvertImportType.Undefined,
          ].includes(importType)
        : true;

export const isBanned = (importType?: AdvertImportType | null) =>
    importType ? [AdvertImportType.ManualBzrRkPronajem, AdvertImportType.ManualBzrSpravceProdej].includes(importType) : false;

export const isOnlyOneOfferType = (importType?: AdvertImportType | null) =>
    importType ? [AdvertImportType.ManualBzrRkProdej, AdvertImportType.ManualBzrSpravcePronajem].includes(importType) : false;

export const isRentAllowed = (importType?: AdvertImportType | null) =>
    importType ? ![AdvertImportType.ManualBzrRkProdej].includes(importType) : true;

export const isSellAllowed = (importType?: AdvertImportType | null) =>
    importType ? ![AdvertImportType.ManualBzrSpravcePronajem].includes(importType) : true;

export const isActivationAllowed = (offerType: OfferType, importType?: AdvertImportType | null) => {
    if (importType && importType !== AdvertImportType.Undefined) {
        switch (offerType) {
            case OfferType.Prodej:
                return isSellAllowed(importType);
            case OfferType.Pronajem:
                return isRentAllowed(importType);
        }
    }

    return true;
};

export const isBrizAllowed = (advert?: {offerType?: OfferType | null; estateType?: EstateType | null} | null) => {
    return (
        advert?.offerType === OfferType.Prodej &&
        !!advert.estateType &&
        [EstateType.Byt, EstateType.Dum, EstateType.RekreacniObjekt].includes(advert.estateType)
    );
};

export const getZipByAddress = async (locale: LocaleType, address?: string) => {
    if (address) {
        const zip = await autocomplete(
            process.env.NEXT_PUBLIC_AUTOCOMPLETE_URI ?? '',
            locale,
            undefined,
            true,
        )(address).then((result) => {
            const zipTmp = result.features.find((feature) => feature.properties.address?.postcode)?.properties.address?.postcode;

            return typeof zipTmp === 'string' ? zipTmp : null;
        });

        if (zip) {
            return zip;
        }
    }
    return null;
};
